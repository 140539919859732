import logo from './logo.svg';
import './App.css';
import Home from './components/home';

function App() {
  return (
    <Home></Home>
  );
}

export default App;
